import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Centered from "../../layout/Centered";
import Synthese from "./Synthese";
import Crd from "./Crd";
import ProfilExtinctionDette from "./ProfilExtinctionDette";
import DscrProjete from "./DscrProjete";
import Vacant from "./Vacant";
import RapprochementAltaix from "./RapprochementAltaix";
import FinancementCorporate from "./FinancementCorporate";
// https://you-fleet.fr/patrimonial/public/rapports/crd

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3, minHeight: 300 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
});

class Rapports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  handleChange = (event, index) => {
    this.setState({ index });
  };

  render() {
    const { index } = this.state;
    const { openNotification } = this.props;
    const rendererHost = "https://madar-patrimoine.2m-advisory.fr/pdf";
    return( 
      <Centered alignItems="flex-start" justify="center">
        <Grid item xs={11}>
          <div className={this.props.classes.root}>
            <AppBar position="static">
              <Tabs value={index} onChange={this.handleChange} centered>
                <Tab label="Synthèse" />
                <Tab label="CRD par banque" />
                <Tab label="Profil d'extinction dette" />
                <Tab label="DSCR PROJETÉ" />
                <Tab label="Rapprochement ALTAIX" />
		            <Tab label="Vacant" />
                <Tab label="Financement Corporate" />
              </Tabs>
            </AppBar>
            {index === 0 && (
              <TabContainer>
                <Synthese rendererHost={rendererHost} />
              </TabContainer>
            )}
            {index === 1 && (
              <TabContainer>
                <Crd rendererHost={rendererHost} />
              </TabContainer>
            )}
            {index === 2 && (
              <TabContainer>
                <ProfilExtinctionDette rendererHost={rendererHost} />
              </TabContainer>
            )}
            {index === 3 && (
              <TabContainer>
                <DscrProjete rendererHost={rendererHost} />
              </TabContainer>
            )}
            {index === 4 && (
              <TabContainer>
                <RapprochementAltaix rendererHost={rendererHost} openNotification={openNotification} />
              </TabContainer>
            )}
            {index === 5 && (
              <TabContainer>
                <Vacant rendererHost={rendererHost} />
              </TabContainer>
            )}
            {index === 6 && (
              <TabContainer>
                <FinancementCorporate rendererHost={rendererHost} />
              </TabContainer>
            )}
          </div>
        </Grid>
      </Centered>
    );
  }
}

export default withStyles(styles)(Rapports);
